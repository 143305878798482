import { ATTACH_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK_TRIAL, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK, FILTERS_EQUAL, FILTERS_STARTS_WITH } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "after-close": _vm.afterModalClose, "confirm-loading": _vm.loading, "destroy-on-close": "", "title": "Add Effective Date" }, on: { "cancel": _vm.onCancel, "ok": function($event) {
      return handleSubmit(_vm.onOk);
    } }, model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", [_c("a-col", { attrs: { "span": 24 } }, [_c("select-input", { attrs: { "all-in-value": "", "custom-search": "", "data-source": _vm.britestacks, "form-item": "", "label": _vm.britestackInputLabel, "mode": "multiple", "placeholder": "Type at least 3 characters to start searching", "rules": "required", "search-by": "ItemCode", "source": "itemCode" }, on: { "change": _vm.onBritestackChange, "search": _vm.onBritestackSearch } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("p", { staticClass: "b7 neutral-9--text" }, [_vm._v("Please select effective date for this attachment.")])]), _c("a-col", { attrs: { "span": 12 } }, [_c("date-picker", { attrs: { "form-item": "", "label": "Effective date" }, on: { "change": function($event) {
      return _vm.storeValue("effDate", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("div", { staticClass: "b7 neutral-9--text" }, [_vm._v("(Leave the date blank if it is effective immediately.)")])])], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "AttachBritestackModal",
  inject: ["resourceProps"],
  props: {
    customUrl: {
      type: String,
      required: true
    },
    trialFlag: {
      type: String,
      required: true
    }
  },
  data() {
    const [attachmentProps, britestackProps] = this.resourceProps;
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      attachmentProps,
      britestackProps,
      loading: false,
      visible: false
    };
  },
  computed: {
    britestacks() {
      return this.britestackProps.crud.getList();
    },
    canOnlyAttachTrialItem() {
      return this.trialFlag == "true";
    },
    britestackInputLabel() {
      if (this.canOnlyAttachTrialItem) {
        return "Select Item Code to attach (trial Item Code only)";
      }
      return "Select Item Code to attach";
    }
  },
  created() {
    this.britestackProps.crud.deleteFilter("IsInactive");
    if (this.$can(ATTACH_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK_TRIAL) || this.$can(ATTACH_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK)) {
      if (this.canOnlyAttachTrialItem) {
        this.britestackProps.crud.setFilter("TrialFlag", {
          operator: FILTERS_EQUAL,
          value: this.trialFlag
        });
      }
    }
  },
  mounted() {
    this.visible = true;
    this.storeValue("effDate", this.$moment().format("YYYY-MM-DD"));
  },
  methods: {
    storeValue(resourceKey, value) {
      if (resourceKey == "effDate" && !value) {
        value = this.$moment().format("YYYY-MM-DD");
      }
      this.attachmentProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    afterModalClose() {
      this.$router.push(this.attachmentProps.redirectRoute);
    },
    onBritestackSearch(value) {
      this.britestackProps.crud.setFilter("ItemCode", {
        operator: FILTERS_STARTS_WITH,
        value
      });
      if (value && value.length >= 3) {
        this.britestackProps.crud.fetchList();
      }
    },
    onBritestackChange(values) {
      let britestackIds;
      if (values && values.length) {
        britestackIds = values.map((value) => value.id);
      }
      this.storeValue("britestackIds", britestackIds);
    },
    onCancel() {
      this.visible = false;
    },
    async onOk() {
      const steriId = this.$route.params.id;
      const url = `${this.apiUrl}/sterilization/data-entries/${steriId}/attachments`;
      this.loading = true;
      try {
        await this.attachmentProps.crud.submitEntity("create", url);
        await this.attachmentProps.crud.fetchList(url);
        this.visible = false;
      } finally {
        this.loading = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var AttachBritestackModal = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("resource", { attrs: { "name": [
    "sterilization.data-entries.attachments",
    "sterilization.common.britestacks"
  ], "api-url": _vm.apiUrl, "redirect-route": [_vm.redirectRoute] } }, [_c("attach-britestack-modal", { attrs: { "custom-url": _vm.customUrl, "trial-flag": _vm.trialFlag } })], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    AttachBritestackModal
  },
  data() {
    const apiUrl = "#{VUE_APP_API_URL}#";
    const steriId = this.$route.params.id;
    const trialFlag = this.$route.query.trialFlag;
    return {
      apiUrl,
      customUrl: `${apiUrl}/sterilization/data-entries/${steriId}/attachments`,
      redirectRoute: `/sterilization-specifications/data-entries/${steriId}`,
      trialFlag
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
